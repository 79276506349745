.login_bg_img{
    height: 100%;
}
.login_bg_img img{
    width: 100%;
    background-size: 100% 100%;
    height: 100%;
}
.login_bg_wrapper{
    background-image: url('../../../assets/images/ucada-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.form_box{
    background-color: #fff;
    padding: 10px;
    border-radius:15px;
}

.login_logo{
    height: 150px;
    /* min-width: 250px; */
}

.password_wrapper{
    position: relative;
}
.view_password{
    position: absolute;
    height: inherit;
    top: 150%;
    right: 2%;
    cursor: pointer;
}